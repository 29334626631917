interface OptionsProps {
    id: number
    description: string
}

interface IProductModalProps {
    id: number | string
    name: string
    description: string
    price: string
    options: OptionsProps[]
}

export interface IProductModalStateProps {
    product: null | IProductModalProps
}

const initialState = {
    product: null
}

export const productModalReducer = (state = initialState, action: any) => {
    
    if(action.type === 'product-modal/change'){
        return {...state, product: action.payload}
    }
    
    return state
}