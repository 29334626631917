import { Route, Routes } from "react-router-dom";
import LayoutPage from './Pages/Layout';
import FecharCaixa from "./Pages/FecharCaixa";

export default function AppRoutes(){
    return (
        <Routes>
            <Route path="/" element={<LayoutPage/>}/>
            <Route path="/fechar" element={<FecharCaixa/>}/>
        </Routes>
    )
}