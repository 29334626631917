const initialState = {
  currentUser: 123,
  name: "Caixa",
  print_url: "http://192.168.15.10/impressora/print.php",
};

export const userReducer = (state = initialState, action: any) => {
  if (action.type === "user/login") {
    return {
      ...state,
      currentUser: action.payload.user_code,
      name: action.payload.name,
      print_url: action.payload.print_url,
    };
  }

  return state;
};
