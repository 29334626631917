export const caixaReducer = (state = 'fechado', action: any) => {
    
    if(action.type === 'caixa/open'){
        return 'aberto'
    }

    if(action.type === 'caixa/close'){
        return 'fechado'
    }
    
    return state
}