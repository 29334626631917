import { useDispatch } from "react-redux";
import { Item } from "./style";

interface ItemCarrinhoProps {
    name: string
    obs: string
    image: string
    qtd: number
    unitPrice: string
    variant_id: string|number
    product_id: string|number
}

export function ItemCarrinhoComponent({name, obs, image, qtd, unitPrice, variant_id, product_id}: ItemCarrinhoProps){
    
    const dispatch = useDispatch()
    const handleUpdateQty = (variant_id: number|string, qty: number) => {
        if(qty > 0){
            dispatch({
                type: 'carrinho/change-quantity',
                payload: {
                    variant_id, qty, product_id
                }
            })
        }else{
            dispatch({
                type: 'carrinho/remove',
                payload: {
                    variant_id, product_id
                }
            })
        }
    }

    return (
        <Item>
            <img src={`https://sistema.adegagordonis.com.br/public/products/cardapio/${image}`} alt={name} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="https://sistema.adegagordonis.com.br/public/products/cardapio/sem-imagem.png";
            }} />
            <div className="infos">
                <strong>{name}</strong>
                <small>{obs}</small>
                <span>R$ {parseFloat(unitPrice).toLocaleString('pt-br', {minimumFractionDigits: 2})}</span>
            </div>
            <div className="actions">
                <button onClick={() => handleUpdateQty(variant_id, qtd -1)}>-</button>
                <span>{qtd}</span>
                <button onClick={() => handleUpdateQty(variant_id, qtd +1)}>+</button>
            </div>
        </Item>
    )
}