import { useEffect, useState } from "react";
import { CarrinhoComponent } from "../../Components/Carrinho";
import { HeaderComponent } from "../../Components/Header";
import { ItemComponent } from "../../Components/Item";
import { LateralComponent } from "../../Components/Lateral";
import { Content, DivItens } from "./style";
import { BiSearchAlt } from 'react-icons/bi'
import api from "../../Services/api";
import { ModalComponent } from "../../Components/Modal";
import { useProductModal } from '../../Hooks/product-modal'

interface ItemProps {
    image: string
    name: string
    price: string|number
    id: string|number
}

export default function PrincipalPage(){
    
    const productModal = useProductModal();

    const [termo, setTermo] = useState<string>('')
    const [itens, setItens] = useState<ItemProps[]>([])

    const [cervejas, setCervejas] = useState<ItemProps[]>([])
    const [whiskies, setWhiskies] = useState<ItemProps[]>([])
    const [vodkas, setVodkas] = useState<ItemProps[]>([])
    const [gins, setGins] = useState<ItemProps[]>([])
    const [caipirinhas, setCaipirinhas] = useState<ItemProps[]>([])
    const [licores, setLicores] = useState<ItemProps[]>([])
    const [draft, setDraft] = useState<ItemProps[]>([])
    const [tabacaria, setTabacaria] = useState<ItemProps[]>([])
    const [doces, setDoces] = useState<ItemProps[]>([])

    useEffect(() => {
        if(termo.length >= 3){
            (async ()=>{
                const itens = await api.get(`/products/search?search=${termo}`)
                setItens(itens.data)
            })()
        }else{
            setItens([])
        }
    }, [termo])

    useEffect(() => {
        (async ()=>{
            const cervejas_api = await api.get(`/products?category_id=7`)
            setCervejas(cervejas_api.data.data)

            const whiskies_api = await api.get(`/products?category_id=21`)
            setWhiskies(whiskies_api.data.data)

            const vodkas_api = await api.get(`/products?category_id=22`)
            setVodkas(vodkas_api.data.data)

            const gins_api = await api.get(`/products?category_id=23`)
            setGins(gins_api.data.data)

            const caipirinhas_api = await api.get(`/products?category_id=2`)
            setCaipirinhas(caipirinhas_api.data.data)
            
            const licores_api = await api.get(`/products?category_id=24`)
            setLicores(licores_api.data.data)

            const draft_api = await api.get(`/products?category_id=25`)
            setDraft(draft_api.data.data)

            const tabacaria_api = await api.get(`/products?category_id=8`)
            setTabacaria(tabacaria_api.data.data)

            const doces_api = await api.get(`/products?category_id=9`)
            setDoces(doces_api.data.data)
        })()
    }, [])

    return (
        <>
            <HeaderComponent title="Novo pedido"/>
            <LateralComponent/>

            <Content>
                <div id="busca-produto">
                    <div>
                        <BiSearchAlt />
                        <input type="text" name="busca" value={termo} placeholder="Buscar produto..." onChange={(e) => setTermo(e.target.value)} />
                    </div>
                </div>
                <DivItens>
                    {itens.length ?
                        itens.map(item => <ItemComponent key={item.id} url="" image={`https://sistema.adegagordonis.com.br/public/products/cardapio/${item.image}`} id={item.id} name={item.name} price={item.price} />)
                    : 
                        <div id="vitrines">
                            <h3>Cervejas</h3>
                            <div className="carrossel">
                                {cervejas.length && cervejas.map(item => <ItemComponent key={item.id} url="" image={`https://sistema.adegagordonis.com.br/public/products/cardapio/${item.image}`} id={item.id} name={item.name} price={item.price} />)}
                            </div>
                            <h3>Whiskies</h3>
                            <div className="carrossel">
                                {whiskies.length && whiskies.map(item => <ItemComponent key={item.id} url="" image={`https://sistema.adegagordonis.com.br/public/products/cardapio/${item.image}`} id={item.id} name={item.name} price={item.price} />)}
                            </div>
                            <h3>Vodkas</h3>
                            <div className="carrossel">
                                {vodkas.length && vodkas.map(item => <ItemComponent key={item.id} url="" image={`https://sistema.adegagordonis.com.br/public/products/cardapio/${item.image}`} id={item.id} name={item.name} price={item.price} />)}
                            </div>
                            <h3>Gin's</h3>
                            <div className="carrossel">
                                {gins.length && gins.map(item => <ItemComponent key={item.id} url="" image={`https://sistema.adegagordonis.com.br/public/products/cardapio/${item.image}`} id={item.id} name={item.name} price={item.price} />)}
                            </div>
                            <h3>Caipirinha</h3>
                            <div className="carrossel">
                                {caipirinhas.length && caipirinhas.map(item => <ItemComponent key={item.id} url="" image={`https://sistema.adegagordonis.com.br/public/products/cardapio/${item.image}`} id={item.id} name={item.name} price={item.price} />)}
                            </div>
                            <h3>Licores, Runs e Kariri </h3>
                            <div className="carrossel">
                                {licores.length && licores.map(item => <ItemComponent key={item.id} url="" image={`https://sistema.adegagordonis.com.br/public/products/cardapio/${item.image}`} id={item.id} name={item.name} price={item.price} />)}
                            </div>
                            <h3>Draft, Ice, Skol Beats e Vinho</h3>
                            <div className="carrossel">
                                {draft.length && draft.map(item => <ItemComponent key={item.id} url="" image={`https://sistema.adegagordonis.com.br/public/products/cardapio/${item.image}`} id={item.id} name={item.name} price={item.price} />)}
                            </div>
                            <h3>Tabacaria</h3>
                            <div className="carrossel">
                                {tabacaria.length && tabacaria.map(item => <ItemComponent key={item.id} url="" image={`https://sistema.adegagordonis.com.br/public/products/cardapio/${item.image}`} id={item.id} name={item.name} price={item.price} />)}
                            </div>
                            <h3>Doces</h3>
                            <div className="carrossel">
                                {doces.length && doces.map(item => <ItemComponent key={item.id} url="" image={`https://sistema.adegagordonis.com.br/public/products/cardapio/${item.image}`} id={item.id} name={item.name} price={item.price} />)}
                            </div>
                        </div>
                    }
                </DivItens>
            </Content>

            <CarrinhoComponent/>


            {productModal.product && <ModalComponent />}
        </>
    )
}