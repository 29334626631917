export interface ICarrinhoProductProps {
    product_id: number | string
    variant_id: number
    qtd: number
    name: string
    image: string
    obs: string
    unitPrice: string
}

const initialState: ICarrinhoProductProps[] = []

export const carrinhoReducer = (state = initialState, action: any) => {
    
    if(action.type === 'carrinho/add'){
        console.log(action.payload)
        const exists = state.some(item => item.variant_id === action.payload.variant_id && item.product_id === action.payload.product_id)
        if(exists){
            const items = state.map(item => {
                if(item.variant_id === action.payload.variant_id && item.product_id === action.payload.product_id){
                    return {...item, qtd: item.qtd + 1}
                }
                return item;
            })
            return [...items]
        }

        return [...state, {...action.payload}]
    }

    if(action.type === 'carrinho/change-quantity'){
        const items = state.map(item => {
            if(item.variant_id === action.payload.variant_id && item.product_id === action.payload.product_id){
                return {...item, qtd: action.payload.qty}
            }
            return item;
        })
        return [...items]
    }

    if(action.type === 'carrinho/remove'){
        const items = state.filter(item => {
            if(item.variant_id !== action.payload.variant_id && item.product_id !== action.payload.product_id){
                return true
            }
            return false
        })
        return [...items]
    }

    if(action.type === 'carrinho/clear'){
        return []
    }
    
    return state
}