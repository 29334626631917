import { useProductModal } from "../../Hooks/product-modal"
import { ModalBackground, ModalDialog, ModalHeader, ModalContent, ModalFooter } from "./style"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import api from "../../Services/api"
import { toast } from "react-toastify"

export const ModalComponent = () => {
    const modal = useProductModal()
    const dispatch = useDispatch()
    const handleCancelModal = () => {
        dispatch({
            type: 'product-modal/change',
            payload: null
        })
    }

    const [variant, setVariant] = useState<string>('')
    const [product, setProduct] = useState({
        id:'',
        image:'',
        name: '',
        price: '',
        description: '',
        variants: [
            {
                id: '',
                name: '',
                price: ''
            }
        ]
    })
    useEffect(() => {
        (async ()=>{
            const productApi = await api.get(`/products/${modal.product}`)
            console.log(productApi.data)
            setProduct(productApi.data)
        })()
    }, [modal.product])

    if(!product){
        return (<>error</>)
    }

    const handleAdicionarAoCarrinho = () => {
        if(variant === '' && product.variants.length > 0){
            toast.error("Selecione uma variação!");
        }else{
            let unitPrice = variant.split('|')[1];
            if(!unitPrice){
                unitPrice = product.price
            }
            dispatch({
                type: 'carrinho/add',
                payload: {
                    product_id: product.id,
                    variant_id: variant.split('|')[0],
                    qtd: 1,
                    name: product.name,
                    image: product.image,
                    obs: variant.split('|')[2],
                    unitPrice
                }
            })
            toast.success("Item adicionado!");
            handleCancelModal()
        }
    }
    return(
        <ModalBackground>
            <ModalDialog>
                <ModalHeader>
                    <h2>Verifique as informações</h2>
                    <p>Verifique se está tudo certo e adicione o item</p>
                </ModalHeader>
                <ModalContent>
                    <div id="productInfos">
                        <img src={`https://sistema.adegagordonis.com.br/public/products/cardapio/${product.image}`} alt="" />
                        <div>
                            <h3>{product.name}</h3>
                            <p>{product.description}</p>
                        </div>
                    </div>
                    {
                    product.variants.length 
                        && 
                    <div>
                        <label htmlFor="">Selecione uma opção</label>
                        <select onChange={(e) => setVariant(e.target.value)}>
                            <option value="">Selecione</option>
                            {product.variants.map(variant => (
                            <option key={variant.id} value={`${variant.id}|${variant.price}|${variant.name}`}>{variant.name} (R$ {parseFloat(variant.price).toLocaleString('pt-br', {minimumFractionDigits: 2})})</option>
                            ))}
                        </select>
                    </div>
                    }
                    <textarea placeholder="Observações (opicional)" rows={2}></textarea>
                    
                </ModalContent>
                <ModalFooter>
                    <button className="danger" onClick={() => handleCancelModal()}>Cancelar</button>
                    <button onClick={() => handleAdicionarAoCarrinho()}>Adicionar</button>
                </ModalFooter>
            </ModalDialog>
        </ModalBackground>
    )
}