import { useEffect } from 'react';
import { useCaixa } from '../../Hooks/caixa';
import { useUser } from '../../Hooks/user';
import FecharCaixa from '../FecharCaixa';
import PrincipalPage from '../Principal';
import api from '../../Services/api';
import { useDispatch } from 'react-redux';


export default function LayoutPage(){

    const auth = useUser();
    const caixa = useCaixa()

    const dispatch = useDispatch()

    useEffect(() => {
        (async () => {
            const caixaApi = await api.get('/caixa')
            if(caixaApi.data.data.status === 'aberto'){
                dispatch({
                    type: 'caixa/open'
                })
            }
        })()
    }, [dispatch])

    if(caixa === 'fechado'){
        return <FecharCaixa/>
    }
    
    if(auth.currentUser){
        return <PrincipalPage/>
    }

    return <PrincipalPage/>
}