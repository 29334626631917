import { ItemCarrinhoComponent } from "./Item";
import {
  Carrinho,
  ContaFinal,
  OtherOptions,
  PaymentOptions,
  CarrinhoButton,
  BoxJaCobrou,
} from "./style";

import { BsCashCoin } from "react-icons/bs";
import { FaRegCreditCard, FaCartShopping } from "react-icons/fa6";
import { MdAttachMoney } from "react-icons/md";
import { IoArrowBackOutline } from "react-icons/io5";
import { PiNoteDuotone } from "react-icons/pi";

import { useCarrinho } from "../../Hooks/carrinho";
import { useState } from "react";
import api from "../../Services/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useUser } from "../../Hooks/user";

export function CarrinhoComponent() {
  //import functions/uses
  const user = useUser();
  const items = useCarrinho();
  const dispatch = useDispatch();

  const [carrinhoOpen, setCarrinhoOpen] = useState<boolean>(false);
  const [jaPagou, setJaPagou] = useState<boolean>(false);

  //Handle functions
  const handlePayOrder = async (
    paymentForm: "money" | "card_machine" | "prego",
    items: any
  ) => {
    const data = JSON.stringify({
      user_id: user.user_code,
      payment_method: paymentForm,
      items: items,
    });

    api
      .post("orders", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((data) => {
        if (data.data.success) {
          setCarrinhoOpen(false);
          setJaPagou(false);
          toast.success("Pedido finalizado com sucesso!");
          dispatch({
            type: "carrinho/clear",
          });
          dispatch({
            type: "user/login",
            payload: {
              user_code: null,
            },
          });
          setCheckout(false);

          api
            .get("/confs/impressora_url")
            .then((conf) => {
              console.log(conf);
              console.log(`http://${conf.data.valor}/${data.data.id}`);
              axios
                .get(`http://${conf.data.valor}/${data.data.id}`)
                .then((sucess) => {})
                .catch((err) => {
                  console.log(err);
                  //toast.warning("Impressora desligada ou sem comunicação");
                });
            })
            .catch((err) => {
              toast.warning("IP da impressora não configurado");
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // States
  const [checkout, setCheckout] = useState<boolean>(false);

  //Component functions
  const qtd = items.reduce((acc, item) => acc + item.qtd, 0);
  const total = items.reduce(
    (acc, item) => acc + parseFloat(item.unitPrice) * item.qtd,
    0
  );
  return (
    <>
      <Carrinho isOpen={carrinhoOpen}>
        {checkout ? (
          <>
            <h1>Fechar pedido</h1>
            {!jaPagou ? (
              <BoxJaCobrou>
                <h1>Já cobrou??</h1>
                <strong>
                  Total: R${" "}
                  {total.toLocaleString("pt-BR", { minimumFractionDigits: 2 })}
                </strong>
                <OtherOptions>
                  <button onClick={() => setJaPagou(true)}>
                    Sim, já cobrei
                  </button>
                  <button
                    className="warning"
                    type="button"
                    onClick={() => setCheckout(false)}
                  >
                    <IoArrowBackOutline /> Voltar
                  </button>
                </OtherOptions>
              </BoxJaCobrou>
            ) : (
              <>
                <p>
                  Escolha uma forma de pagamento para concluir, ou{" "}
                  <u>voltar para adicionar mais itens</u>.
                </p>
                <PaymentOptions>
                  <button
                    type="button"
                    onClick={() => handlePayOrder("card_machine", items)}
                  >
                    <FaRegCreditCard /> Maquininha
                  </button>
                  <span>ou</span>
                  <button
                    type="button"
                    onClick={() => handlePayOrder("money", items)}
                  >
                    <MdAttachMoney /> Dinheiro
                  </button>
                  <span>ou</span>
                  <button
                    type="button"
                    onClick={() => handlePayOrder("prego", items)}
                  >
                    <PiNoteDuotone /> Prego
                  </button>
                </PaymentOptions>
                <OtherOptions>
                  <button type="button" onClick={() => setCheckout(false)}>
                    <IoArrowBackOutline /> Voltar
                  </button>
                </OtherOptions>
              </>
            )}
          </>
        ) : (
          <>
            <h1>Resumo</h1>
            {items.length === 0 ? (
              <div className="sem-itens">
                <h2>Ainda sem nenhum item :( </h2>
                <p>
                  Use a busca ao lado e clique em um item para adicionar e
                  iniciar a venda
                </p>
              </div>
            ) : (
              <div className="resumo-itens">
                <strong>{qtd} produtos</strong>
                <div id="listaItens">
                  {items.map((item) => (
                    <ItemCarrinhoComponent
                      {...item}
                      key={`item${item.product_id}-variant${item.variant_id}`}
                    />
                  ))}
                </div>
                <ContaFinal>
                  <h3>Conta</h3>
                  <table>
                    <tbody>
                      <tr>
                        <td>Produtos</td>
                        <td>
                          R${" "}
                          {total.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Total</td>
                        <td>
                          R${" "}
                          {total.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </ContaFinal>
                <div id="acoes-conta">
                  <button
                    className="pagar"
                    onClick={() => {
                      setCheckout(true);
                      setJaPagou(false);
                    }}
                  >
                    <BsCashCoin /> Fechar pedido
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </Carrinho>
      <CarrinhoButton onClick={() => setCarrinhoOpen(!carrinhoOpen)}>
        <FaCartShopping /> {carrinhoOpen ? `Esconder` : "Ver"} itens{" "}
        <span>{qtd}</span>
      </CarrinhoButton>
    </>
  );
}
