import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ToastContainer } from 'react-toastify'

import { BrowserRouter } from 'react-router-dom'

import { Provider } from 'react-redux'
import store from './Redux/store'

import 'react-toastify/dist/ReactToastify.min.css'
import AppRoutes from './Routes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AppRoutes/>
      </BrowserRouter>
      <ToastContainer theme="colored" />
    </Provider>
  </React.StrictMode>
);

