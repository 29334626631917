import { useDispatch } from "react-redux";
import { useCaixa } from "../../Hooks/caixa";
import api from "../../Services/api";
import { Header } from "./style";

interface HeaderProps {
    title: string
}

export function HeaderComponent({title}: HeaderProps){

    const dispatch = useDispatch()
    
    const caixa = useCaixa()
    const handleCloseCaixa = async () => {
        if(window.confirm('Confirma o fechamento do caixa?')){
            await api.post(`/caixa/close`)
            dispatch({
                type: 'caixa/close'
            })
        }
    }

    return (
    <Header>
        <h1>{title}</h1>
        <span>
            {caixa === 'aberto' && <button onClick={() => handleCloseCaixa()}>Fechar caixa</button>} Caixa: <b className={`caixa-${caixa}`}>{caixa}</b>
        </span>
    </Header>
    )
}