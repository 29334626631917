import styled from 'styled-components'

export const Header = styled.div`
    width: 100%;
    position: fixed;
    border-bottom: 2px solid #363637;
    display: flex;
    padding: 1rem 1rem 1rem 110px;
    justify-content: space-between;
    background-color: #282829;

    h1 {
        color: #fff;
        font-size: 1.2rem;
    }

    span {
        font-size: 1rem;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    a, button {
        text-decoration: none;
        background-color: #4caf50;
        font-size: 0.9rem;
        font-weight: 600;
        padding: 3px 16px;
        border-radius: 10px;
        display: flex;
        gap: 5px;
        color: #fff;
        border: 0;
        box-shadow: 0 0 5px #000;
    }

    @media screen and (max-width: 768px){
        padding-left: 1rem;
    }
`