import { Item } from "./style"
import { useDispatch } from "react-redux"

interface ItemProps {
    id: string | number
    url: string
    image: string
    name: string
    price: number|string
}

export function ItemComponent({id, image, name, price}: ItemProps){

    const dispacth = useDispatch();
    const handleItemClick = () => {
        dispacth({
            type: "product-modal/change",
            payload: id
        })
    }

    return (
        <Item onClick={() => handleItemClick()}>
            <img src={image} alt={name} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="https://sistema.adegagordonis.com.br/public/products/cardapio/sem-imagem.png";
            }} />
            <strong>{name}</strong>
            {/* <span>R$ {price.toLocaleString('pt-br', {minimumFractionDigits: 2})}</span> */}
        </Item>
    )
}