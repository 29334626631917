import styled from 'styled-components'

export const Item = styled.a`
    display: flex;
    flex-direction: column;
    background-color: #282829;
    overflow: hidden;
    border-radius: 5px;
    text-decoration: none;
    max-width: 100px;

    img {
        width: 100px;
        height: 100px;
    }

    strong {
        color: #fff;
        width: 100%;
        padding: 10px 10px 0 10px;
        font-size: 0.9rem
    }

    span {
        color: #ffdc0f;
        padding: 10px;
    }
`