import { combineReducers } from 'redux'
import { userReducer } from './user/reducer'
import { IProductModalStateProps, productModalReducer } from './productModal/reducer'
import { ICarrinhoProductProps, carrinhoReducer } from './carrinho/reducer'
import { caixaReducer } from './caixa/reducer'

export interface IRootReducerProps {
    productModalReducer: IProductModalStateProps,
    userReducer: any,
    carrinhoReducer: ICarrinhoProductProps[]
    caixaReducer: 'fechado'|'aberto'
}

const rootReducer = combineReducers({
    userReducer,
    productModalReducer,
    carrinhoReducer,
    caixaReducer
})

export default rootReducer