import styled from 'styled-components'

export const Item = styled.div`
    background-color: #3d3d3d;
    padding: .5rem;
    margin: 10px 0;
    border-radius: 4px;
    box-shadow: 0 0 10px #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    img {
        width: 50px;
        height: 50px;
        border-radius: 4px;
    }

    .infos {
        display: flex;
        flex-direction: column;
        flex: 1;

        strong {
            color: #fff;
            font-size: 1rem;
        }

        small {
            color: #fff;
        }

        span {
            color: #ffdc0f;
            font-size: 1rem;
        }
    }

    .actions {
        display: flex;
        align-items: center;
        gap: 5px;

        span {
            color: #fff;
            font-size: 1.3rem;
            font-weight: bold;
            width: 30px;
            text-align: center;
        }

        button {
            background-color: #ffdc0f;
            border: 0;
            font-size: 1.3rem;
            font-weight: bold;
            border-radius: 4px;
            width: 30px;
            height: 30px;
        }
    }
`