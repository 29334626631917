import styled from 'styled-components'

export const Content = styled.div`
    padding: 100px 1rem 1rem 110px;
    width: 100%;

    @media screen and (max-width: 768px){
        padding-left: 1rem;
        width: 100%;
    }
`

export const FormGroup = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;

    span {color: #fff;}

    b {color: #fff;}

    label {
        color: #fff;
        margin-bottom: 10px;
    }

    > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        
        div {
            display: flex;
            align-items: center;
            gap: 10px;

            
            input {
                padding: 8px 12px;
                border-radius: 4px;
                border: 1px solid #3a3a3a;
                background-color: #000;
                color: #fff;
            }
        }
    }

    button {
        background-color: #4caf50;
        color: #fff;
        font-size: 1rem;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 10px;
        display: flex;
        gap: 5px;
        border: 0;
        box-shadow: 0 0 5px #000;
        cursor: pointer;
    }
`

export const ResultadoCaixa = styled.div`
    display: flex;
    margin-top: 20px;
    justify-content: space-around;

    .box {
        display: flex;
        flex-direction: column;
        background-color: #282829;
        overflow: hidden;
        border-radius: 5px;
        text-decoration: none;
        width: 30%;
        justify-content: center;
        align-items: center;
        padding: 15px 0;

        h2 {
            color: #fff;
            font-size: 1.1rem;
        }

        h1 {
            color: #fff;
            font-size: 1.5rem;
            margin-top: 20px;
        }
    }
`