import styled from 'styled-components'

export const ModalBackground = styled.div`
    width: 100%;
    height: 100vh;
    background-color: rgba(23,23,23, 0.8);
    backdrop-filter: blur(6px);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ModalDialog = styled.div`
    background-color: #000;
    padding: 2rem;
    border-radius: 10px;
    border: 1px solid #323232;
    width: 100%;
    max-width: 425px;
`

export const ModalHeader = styled.header`
    h2 {
        color: #fff;
        font-size: 1.3rem;
        margin: 0 0 5px 0;
    }

    p {
        color: #a7a7a7;
        font-size: .875rem
    }
`

export const ModalContent = styled.div`
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    div {
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
            color: #fff;
            font-size: 1rem;
        }

        select {
            padding: 8px 12px;
            border-radius: 4px;
            border: 1px solid #3a3a3a;
            background-color: #000;
            color: #fff;
        }
    }

    textarea {
        padding: 8px 12px;
        border-radius: 4px;
        border: 1px solid #3a3a3a;
        background-color: #000;
        color: #fff;
        resize: none;
    }

    #productInfos {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-bottom: 15px;

        img {
            max-width: 80px;
        }

        h3 {
            color: #fff;
            font-size: 1.1rem
        }

        p {
            color: #fff;
            font-size: 1rem;
        }
    }
    
`

export const ModalFooter = styled.footer`
    display: flex;
    justify-content: space-between;

    button {
        background-color: #fff;
        font-size: 1rem;
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 10px;
        display: flex;
        gap: 5px;
        border: 0;
        box-shadow: 0 0 5px #000;
        cursor: pointer;
        
        &.danger {
            background-color: #be3c32;
            color: #fff;
        }
    }
`