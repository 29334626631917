import styled from 'styled-components'

export const Content = styled.div`
    padding: 100px 1rem 1rem 110px;
    width: 62%;

    #busca-produto {
        margin-bottom: 30px;
        display: flex;
        width: 100%;

        input {
            width: 100%;
            background-color: #282829;
            padding: 10px 15px 10px 35px;
            color: #fff;
            border: 0;
            border-radius: 7px;
            font-size: 1rem;
        }

        div {
            flex: 1;
            display: flex;
        }

        svg {
            color: #757572;
            width: 25px;
            height: 25px;
            margin: 8px -31px 0 6px;
            position: relative;
        }

    }

    #nenhum-resultado {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h3 {
            color: #fff;
            font-size: 1.5rem;
        }

        p {
            color: #fff;
        }
    }

    @media screen and (max-width: 768px){
        padding-left: 1rem;
        width: 100%;
    }
`

export const DivItens = styled.div`
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: auto;
    gap: 1rem;

    #vitrines {
        h3 {
            color: #000;
            font-size: 1.2rem;
            margin-bottom: 15px;
            background: #ffdc0f;
            padding: 4px;
            border-radius: 4px;
        }

        .carrossel {
            display: flex;
            flex-wrap: wrap;
            grid-template-columns: auto;
            gap: 1rem;
            margin-bottom: 25px;
        }
    }
`